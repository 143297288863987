/* NotificationIcon.css */
.notification-container {
    position: fixed;
    top: 52px;
    right: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    z-index: 9999;
  }
  
  .notification-icon {
    color: #007BFF;
    font-size: 20px;
  }
  
  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #FF3B3B;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 50%;
    border: 2px solid white;
  }
  

  /* Notifications.css */
/* .notifications-container {
  position: fixed;
  top: 4px;
  right: 4px;
  max-width: 480px;
  min-width: 480px;

  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  z-index: 9999;
  background-color: white;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  height: 85vh;
  overflow-x:hidden;

} */
@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.notifications-container {
  position: fixed;
  right: 20px;
  top: 70px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  width: 480px;
  max-height: 80vh;
  height: 80vh;
  overflow-y: auto;
  z-index:1000;
  padding: 16px;
  font-size: 14px; /* Reduced base font size */
  animation: slideInFromRight 0.3s ease-in-out;
}


.notification-h1 {
  text-align: center;
  color: #333;
  max-width: 480px;
  min-width: 480px;
}

.severity-group {
  /* margin: 16px 8px 8px 8px; */
  width: 100%; 
  max-width: 100%; 
}

.notification-h2 {
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  z-index: 9999;
  max-width: 480px;
  min-width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Promijenjeno iz center u flex-start */
  gap: 10px; /* Dodajemo prostor između contenta i context menija */

}

/* .notification {
  background-color: #f0f0f0;
  border-left: 5px solid;
  padding: 10px;
  margin-left: 20px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

} */

.notification {
  padding: 12px;
  margin-top: 8px;
  margin-left: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  cursor: pointer;
  border-left: 4px solid;
  background-color: #f0f0f0;
}

/* .notification-title {
  background-color: #e0e0e0;
  border-left: 5px solid;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-weight: bold;


} */

.notification-title {
  font-weight: 600;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  background-color: #e0e0e0;
  margin-left: 8px;
}

.error-container {
  width: 90%; 
  max-width: 90%; 
  overflow-x:hidden;
  white-space: pre-wrap; 
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  /* padding: 10px; */
}

/* .notification.high {
  border-left-color: #ff6b6b; 
}



.notification.medium {
  border-left-color: #ffa500; 
}

.notification.low {
  border-left-color: #4caf50;
} */

.notification.low {
  /* background: #f8f9fa; */
  border-color: #4dabf7;
  /* color: #1864ab; */
}

.notification.medium {
  /* background: #fff3bf; */
  border-color: #fcc419;
  /* color: #e67700; */
}

.notification.high {
  /* background: #ffe3e3; */
  border-color: #ff6b6b;
  /* color: #c92a2a; */
}

.notification p {
  margin: 0;
  color: #333;
}

.notification-actions button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.notification-actions button:hover {
  background-color: #0056b3;
}
.notification-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 0.8em;
  color: #666;
}

.notification-user {
  font-weight: 500;
  margin-right: 10px;
}

.notification-time {
  font-style: italic;
}


.notification-content {
  flex: 1; /* Da zauzme dostupan prostor */
  min-width: 0; /* Za pravilno wrapanje teksta */
}

.notification-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 0.8em;
  color: #666;
  width: 100%; /* Osigurava da meta podaci zauzimaju cijelu širinu */
}

.tabs-container {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
}

.tab-item {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9em;
  color: #666;
  position: relative;
  transition: all 0.2s ease;
}

.tab-item.active {
  color: #007BFF;
  font-weight: 500;
}

.tab-item.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #007BFF;
}

.tab-item:hover {
  color: #0056b3;
}

.reply-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 24px;

}

.reply-input {
  width: 100%;
  min-height: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;

}

.reply-submit {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reply-submit:hover {
  background-color: #0056b3;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .notifications-container {
  animation: slideIn 0.3s ease-out;
} */

@media (max-width: 768px) {
  .notifications-container {
    width: 90%;
    left: 5%;
    top: 60px;
    padding: 12px;
  }
  .notification-container {
    left: 5%;
    top: 10px;
    /* padding: 12px; */
  }
  
  .notification {
    font-size: 14px;
    padding: 10px;
  }
}

.notification:hover {
  /* transform: translateX(1px); */
  box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
}

.notification-title:hover {
  background: rgba(0,0,0,0.05);
  background: #c0c0c0;

}